import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ChainInfo from "../../views/chain-info";
import { Seo } from "../../components/seo";
import { getBanner } from "../../components/utils";
import NetworkMap from "../../views/chain-parts/network-map";

export const Head = ({ location, data: { chainsJson: chain, allFile: files } }) => (
  <Seo
    title={`${chain.name} network map`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
);

const Chain = props => {
  const { chainsJson, allFile } = props.data;
  return (
    <Layout>
      <ChainInfo chain={chainsJson} data={allFile.edges} />
      <NetworkMap chain={chainsJson} data={allFile.edges} />
    </Layout>
  );
};

export default Chain;

export const query = graphql`
  query($key: String!) {
    chainsJson(key: { eq: $key }) {
      id
      about
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            # binary
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;
